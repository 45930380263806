import { Cursor_120 } from "@carbon/icons-react";
import Link from "next/link";
import { useTranslation } from "react-i18next";

import Button from "../../ui/Button";

interface Props {
  reset: () => void;
}

const DemoDone = ({ reset }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="pointer-events-none fixed bottom-0 left-0 z-50 m-2 flex justify-center md:right-0 md:m-6">
      <div className="pointer-events-auto flex items-center space-x-2 rounded bg-white p-3 shadow-lg">
        <div className="md:flex-center hidden h-8 w-8 rounded bg-indigo-100 text-indigo-600 md:block">
          <Cursor_120 />
        </div>

        <span className="hidden uppercase tracking-wide text-indigo-600 md:inline-block lg:pr-24">
          {t("demoModal.demoMode", "Demo mode")}
        </span>

        <Button onClick={reset} type="text">
          {t("demoModal.reset", "Restart tutorial")}
        </Button>

        <Link passHref href="https://app.genesis.live">
          <Button type="primary" Component="a">
            {t("demoModal.createAccount", "Create an account")}
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default DemoDone;
