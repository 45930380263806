import cn from "classnames";
import { useEffect } from "react";

import useAnimation from "~/hooks/useAnimation";
import useKonamiCode from "~/hooks/useKonamiCode";

const EasterEgg = () => {
  const { success, reset } = useKonamiCode();
  if (success) {
    return (
      <div className="flex-center pointer-events-none fixed bottom-0 left-0 right-0 top-0 z-[9999] h-screen w-screen">
        <TractorAnimation reset={reset} />
      </div>
    );
  } else {
    return null;
  }
};

const TractorAnimation = ({ reset }: { reset: () => void }) => {
  const { ref, animation } = useAnimation({ path: "/animations/tractor.json" });

  useEffect(() => {
    const timeout = setTimeout(reset, 20000);
    return () => clearTimeout(timeout);
  }, [reset]);

  return (
    <div
      ref={ref}
      className={cn("h-full w-full transform transition-transform", {
        "-translate-x-full": !animation?.isLoaded,
        "translate-x-full duration-10000 ease-in": animation?.isLoaded,
      })}
    />
  );
};

export default EasterEgg;
