import { filterHead } from "./helpers";

interface Options {
  allowEmptyCampaignValue: boolean;
}

type WithCampaign = { campaign?: { name: string } | null };

function applyCampaignFilter<T extends WithCampaign>(
  array: T[],
  campaignFilter?: string | null,
  options?: Options
): T[];
function applyCampaignFilter(
  array: undefined,
  campaignFilter?: string | null,
  options?: Options
): undefined;
function applyCampaignFilter<T extends WithCampaign>(
  array: T[] | undefined,
  campaignFilter?: string | null,
  options?: Options
) {
  const defaultOptions = { allowEmptyCampaignValue: false };
  const { allowEmptyCampaignValue } = {
    ...defaultOptions,
    ...options,
  };

  if (!array || !campaignFilter) {
    return array;
  } else {
    const filtered = filterHead(
      array,
      ({ campaign }) => !!campaign?.name && campaign.name <= campaignFilter
    );

    if (
      filtered.length > 0 &&
      (allowEmptyCampaignValue || filtered[0].campaign?.name === campaignFilter)
    ) {
      return filtered;
    } else {
      return [];
    }
  }
}

export default applyCampaignFilter;
