/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Measures } from "@prisma/client";
import { ExtendedVersionEnum, Prisma } from "@prisma/client";
import { uniq } from "lodash";

import impactIndicatorDependenciesLegacy from "~/lib/data/impactDependencies-legacy.json";
import impactIndicatorDependenciesV1 from "~/lib/data/impactDependencies-v1.json";
import impactIndicatorDependenciesV2 from "~/lib/data/impactDependencies-v2.json";
import { addNormalized } from "~/lib/formats";
import genuJson from "~/locales/genu.json";

import type {
  EcosystemicFunction,
  EcosystemicFunctionType,
  Genu,
  GenuIndicator,
  IndexType,
  IndicatorType,
} from "./types";

// @todo: Refactor & simplify genu lib

const genu = genuJson as Genu;

export const sortedImpactIndices: IndexType[] = [
  "biodiversityImpact",
  "climateImpact",
  "waterImpact",
  "yieldImpact",
];

export const isIndexType = (ind?: string | null): ind is IndexType => {
  return !!ind && (ind === "impactRating" || (sortedImpactIndices as unknown[]).includes(ind));
};

export const isGenuCode = (ind: string): ind is IndicatorType => {
  return (genu.indicators as Record<string, GenuIndicator>)[ind] !== undefined;
};

export const isIndicator = (ind: string): ind is IndicatorType => {
  return isGenuCode(ind) && genu.indicators[ind].type === "Indicator";
};

export const isMeasure = (ind: string): ind is IndicatorType => {
  return isGenuCode(ind) && genu.indicators[ind].type === "Measure";
};

export const isContextKey = (key: string): key is IndicatorType & keyof Measures => {
  return (
    isGenuCode(key) &&
    genu.indicators[key].index === "Context" &&
    !!Prisma.validator<Prisma.MeasuresUpdateInput>()({ [key]: null } as {
      [key: string]: never;
    })
  );
};

export const isEcosystemicFunction = (key: string): key is EcosystemicFunctionType => {
  return (genu.ecosystemicFunctions as Record<string, EcosystemicFunction>)[key] !== undefined;
};

const greenbackIndicatorDependencies = (version: ExtendedVersionEnum) => {
  if (version === ExtendedVersionEnum.v1) {
    return impactIndicatorDependenciesV1.dependencies as any;
  } else if (version === ExtendedVersionEnum.v2) {
    return impactIndicatorDependenciesV2.dependencies as any;
  } else {
    return impactIndicatorDependenciesLegacy.dependencies as any;
  }
};

export const getDependencyWeights = (
  key: IndexType | IndicatorType,
  version: ExtendedVersionEnum = ExtendedVersionEnum.none
) => {
  return greenbackIndicatorDependencies(version)?.[key]?.weights;
};
// get the nested indicator dependencies
export const getImpactIndicatorDependencies = (
  key: IndexType | IndicatorType,
  version: ExtendedVersionEnum = ExtendedVersionEnum.none
): Array<IndexType | IndicatorType> => {
  return uniq(
    (
      (greenbackIndicatorDependencies(version)?.[key]?.dependencies ?? []) as Array<
        IndexType | IndicatorType
      >
    ).flatMap((dependency) => {
      const deps = getImpactIndicatorDependencies(dependency, version);
      if (deps.length === 0) {
        return [dependency];
      } else {
        return [dependency, ...deps];
      }
    })
  );
};

export const getImpactIndicatorNormalizedDependencies = (
  key: IndexType | IndicatorType,
  version: ExtendedVersionEnum = ExtendedVersionEnum.none
) => {
  return getImpactIndicatorDependencies(key, version).filter(
    (key: string) => key !== "pollution_normalized" && key.endsWith("_normalized")
  );
};

export const indToGetter = (ind: GenuIndicator) => {
  if (!ind.code) {
    return "rating.impactRating";
  }

  return isIndexType(ind.code) ? `rating.${ind.code}` : `indicators.${addNormalized(ind.code)}`;
};

export default genu;
