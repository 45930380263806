/* eslint-disable @typescript-eslint/no-explicit-any */
import type {
  CalculatedPractice,
  ParcelIndicators,
  ParcelInformation,
  ParcelRating,
} from "@prisma/client";
import { get, maxBy } from "lodash";

import type { ParcelDerivedMeasure, RatedParcel } from "~/lib/rated";
import type { CampaignScore } from "~/lib/rating";
import type { Campaign } from "~/services/campaigns/campaignsQuery";

import type { PartialDeep } from "./types";

interface RatedEntry {
  campaignCode?: string;
  campaignName?: string;
  campaign?: Campaign;
  hidden?: boolean;
}

export interface Rated {
  indicators?: RatedEntry[];
  ratings?: RatedEntry[];
}

export const getCampaignValues = (ratedObj: Rated | undefined, getter: string): CampaignScore[] => {
  const [category, key] = getter.split(".");

  const campaignsScore = (category === "rating" ? ratedObj?.ratings : ratedObj?.indicators)?.map(
    (scoreable) => ({
      campaignCode: scoreable.campaign?.code ?? scoreable.campaignCode,
      campaignName: scoreable.campaign?.name ?? scoreable.campaignName,
      hidden: scoreable.hidden,
      value: get(scoreable, key) ?? null,
    })
  );

  return campaignsScore ?? [];
};

export const getParcelLatestValue = (
  parcel: {
    indicators: Partial<ParcelIndicators>[];
    ratings: Partial<ParcelRating>[];
  },
  getter: string
): number => {
  // indicators and ratings are sorted anti-chronologically
  const [indicators] = parcel.indicators;
  const [rating] = parcel.ratings;

  if (!indicators || !rating) {
    throw new Error("unrated parcel");
  }

  const [category, key] = getter.split(".");

  if (category === "indicators") {
    return get(indicators, key);
  }

  if (category === "rating") {
    return get(rating, key);
  }

  throw new Error(`Invalid getter ${getter}`);
};

export const getParcelLatestMeasures = (
  parcel: PartialDeep<Pick<RatedParcel, "hsusIntersections">>,
  getter: string // in the form "measuresCompleted.<measureName>"
): number[] | undefined => {
  return parcel.hsusIntersections?.map((intersection) =>
    get(intersection?.hsu?.samples?.[0], getter)
  );
};

// allows getting the information as `information.metric` instead of `informations[0].metric`.
export const getParcelLatestInformation = <Getter extends string>(
  parcel: PartialDeep<Pick<RatedParcel, "informations">>,
  getter: Getter
): Getter extends `information.${infer Key extends keyof ParcelInformation}`
  ? ParcelInformation[Key] | undefined
  : any => {
  return get(parcel.informations?.[0], getter.split(".").slice(1).join("."));
};

export const getParcelLatestRotationScore = (
  calculatedPractices: PartialDeep<CalculatedPractice>[],
  campaignName?: string
) => {
  const campaignYear = campaignName ? parseInt(campaignName) : undefined;
  for (const { seasonName, rotationScore } of calculatedPractices) {
    const season = seasonName ? parseInt(seasonName) : undefined;
    if (campaignYear !== undefined && season) {
      if (season > campaignYear) {
        continue;
      }

      if (season + 2 < campaignYear) {
        return; // rotation score is too old, ignore it
      }
    }

    if (rotationScore !== null && rotationScore !== undefined) {
      return rotationScore;
    }
  }
};

export const getParcelLatestCalculatedPractice = (
  parcel: PartialDeep<Pick<RatedParcel, "calculatedPractices">>,
  getter: string,
  campaignName?: string
) => {
  if (!parcel.calculatedPractices) {
    return;
  }

  const key = getter.split(".").slice(1).join(".");
  if (key === "rotationScore") {
    return getParcelLatestRotationScore(parcel.calculatedPractices, campaignName);
  }

  return get(parcel.calculatedPractices?.[0], key);
};

export const getRatedParcelLatestCulture = ({ cultures }: RatedParcel) => {
  if (!cultures || cultures.length === 0) {
    return null;
  }

  return maxBy(cultures, "seasonName");
};

export const getParcelLatestDerivedMeasure = (
  parcel: Pick<RatedParcel, "derivedMeasures">,
  getter: keyof ParcelDerivedMeasure,
  campaignName?: string | null
) => {
  if (!parcel.derivedMeasures) {
    return;
  }

  for (const measures of parcel.derivedMeasures) {
    if (campaignName && measures.campaign?.name && measures.campaign.name > campaignName) {
      continue;
    }

    if (measures[getter] !== null && measures[getter] !== undefined) {
      return measures[getter] as number;
    }
  }
};
