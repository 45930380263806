import type { Session } from "next-auth/index";
import { useTranslation } from "react-i18next";

import useAdminImpersonate from "~/hooks/admin/useAdminImpersonate";

import Button from "../ui/Button";

const ImpersonateControl = ({ session }: { session: Session }) => {
  const { t } = useTranslation();
  const { exitImpersonation } = useAdminImpersonate();

  return (
    <div className="flex-center no-print group fixed top-0 z-50 h-2 w-full border-indigo-200 bg-indigo-500 transition-all hover:h-12 hover:border-b hover:bg-indigo-50">
      <div className="flex-center mx-auto rounded-b px-3 py-1 text-sm font-medium opacity-0 transition-all group-hover:opacity-100">
        {t("admin.impersonating", "Impersonating {{email}}", {
          email: session!.user.email,
        })}

        <Button onClick={exitImpersonation} type="primary" className="ml-4 !text-white">
          {t("admin.exitImpersonationCTA", "Exit")}
        </Button>
      </div>
    </div>
  );
};

export default ImpersonateControl;
