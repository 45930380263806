import { Email16 } from "@carbon/icons-react";
import type { FormEventHandler } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "~/components/ui/Button";
import TextInput from "~/components/ui/TextInput";

interface FormElements extends HTMLFormControlsCollection {
  email: string;
}

interface AirtableFormElement extends HTMLFormElement {
  readonly elements: FormElements;
}

const AirtableEmailContactForm = () => {
  const { t } = useTranslation();
  const [formStatus, setFormStatus] = useState<"idle" | "submitting" | "success" | "error">("idle");

  const onSubmit: FormEventHandler<AirtableFormElement> = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setFormStatus("submitting");

    try {
      if (!event.currentTarget.email.value) {
        throw new Error("Email is required");
      }

      const res = await fetch("/api/register-email", {
        method: "POST",
        body: JSON.stringify({ email: event.currentTarget.email.value }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (res.status !== 200) {
        throw new Error(res.statusText);
      }

      setFormStatus("success");
    } catch {
      setFormStatus("error");
    }
  };

  if (formStatus === "success") {
    return (
      <p className="text-sm text-indigo-500">
        {t("demoModal.contactForm.success", "Your email was successfully registered.")}
      </p>
    );
  }

  if (formStatus === "error") {
    return (
      <button className="text-sm text-red-700" onClick={() => setFormStatus("idle")}>
        {t("demoModal.contactForm.error", "Oups, an error occured. Click to try again.")}
      </button>
    );
  }

  return (
    <form onSubmit={onSubmit} className="flex w-full space-x-2">
      <TextInput
        className="flex-grow"
        name="email"
        type="email"
        placeholder={t("demoModal.contactForm.placeholder", "your@email.com")}
      />

      <Button
        loading={formStatus === "submitting"}
        type="dark"
        iconRight={<Email16 />}
        htmlType="submit"
      >
        {t("demoModal.contactForm.submitButton", "Register")}
      </Button>
    </form>
  );
};

export default AirtableEmailContactForm;
